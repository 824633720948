header {
    background-color: #eaf3d5;
    font-size: calc(10px + 2vmin);
    color: rgb(5, 44, 25);
}

header #logo {
    pointer-events: none;
}

#content {
    background-color: #d1e5ee;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.step-enter {
    opacity: 0;
}

.step-exit {
    opacity: 1;
}

.step-enter-active {
    opacity: 1;
}

.step-exit-active {
    opacity: 0;
}

.step-enter-active,
.step-exit-active {
    transition: opacity 500ms;
}

input {
    height: 3rem;
}

textarea {
    height: 7rem;
}

input {
    height: 3rem;
    width: 100%;
    padding: 0;
    // margin: 0 5px;
    border-radius: 10px;
    font-size: 2rem;
    font-weight: bold;
    font-family: 'Acme';
    text-align: center;
}

textarea {
    height: 6rem;
    width: 100%;
    padding: .5rem;
    // margin: 0 5px;
    border-radius: 10px;
    font-size: 1.5rem;
    font-weight: bold;
    // font-family: 'Acme';
}

* label {
    padding-right: 4px;
}

* label,
span {
    font-size: 1.3rem;
    font-weight: bold;
}