/* button.module.css */

.btn {
    font-family: 'Ubuntu', sans-serif;
    position: relative;
    height: 70px;
    margin: 30px 0;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 2;
    transition: all 200ms linear;
    border-radius: 4px;
    width: 240px;
    letter-spacing: 1px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-self: center;
    border: none;
    cursor: pointer;
    box-shadow: 0 12px 35px 0 rgba(16, 39, 112, .25);
    outline: 0;
    text-transform: capitalize;
    &:disabled {
        color: rgba(177, 177, 177, .4);
        box-shadow: none;
        border: solid 2px #dadada;
        cursor: not-allowed;
        pointer-events: all !important;
    }
}