.checkbox {
    display: flex;
    align-items: center;

    height: 23px;
    width: 23px;
    border: solid 2px black;
    border-radius: 5px;
    padding: 0px 4px;
    font-weight: bolder;
    font-size: 1rem;
    
    &.checked:after {
        content: 'X';
        padding-top: 2.5px;
    }
}