.button {
    width: 100%;
    height: 100px;
    padding: 10px 0;
    background: #f3f0f1;
    position: relative;
    background: #f3f0f1;
    /* margin: 10px 10px; */
    border-radius: 32px;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border: None;
}

.button * {
    opacity: .8;
    -webkit-filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
    filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
}

.button {
    box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8), 6px 6px 10px rgba(0, 0, 0, 0.2);
    /* color: #6f6cde; */
}

.button:hover {
    opacity: 1;
    box-shadow: -6px -6px 10px rgba(255, 255, 255, 0.8), 6px 6px 10px rgba(0, 0, 0, 0.2);
}

.button.active {
    opacity: 1;
    box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5), inset 8px 8px 16px rgba(0, 0, 0, 0.1);
    border-color: #00bbf2 !important;
    border-bottom: solid;
}

.button.active * {
    opacity: 1;
    -webkit-filter: none;
    filter: none;
}