.checkbox {
    display: inline-block;
    height: 23px;
    width: 23px;
    border: solid 2px black;
    border-radius: 2px;
    padding: 0px 4px;
    font-weight: bolder;
    font-size: 1rem;
    &.checked:after {
        content: 'X';
    }
}