.button {
    width: 100px;
    height: 160px;
    padding: 0px 7px;
    background: #f3f0f1;
    border-radius: 5px;
    margin: 15px;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border: None;
    &.compact {
        // height: 100px;
        margin-top: 0;
    }
    &.active {
        opacity: 1;
        box-shadow: inset -4px -4px 8px rgba(255, 255, 255, 0.5), inset 8px 8px 16px rgba(0, 0, 0, 0.1);
        border-color: #00bbf2 !important;
        border-bottom: solid;
    }
    * {
        // opacity: .8;
        -webkit-filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
        filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .2));
    }
    &.active * {
        opacity: 1;
        -webkit-filter: none;
        filter: none;
    }
    .icon {
        margin: 7px 0;
        font-size: 1.5rem;
    }
    .name {
        display: block;
        font-size: 1em;
        font-weight: bold;
        margin: 5px 0;
    }
    .checkbox {
        height: 20px;
        width: 20px;
        border: solid 2px black;
        border-radius: 2px;
        margin: 5px auto;
        padding: 0px;
        font-weight: bolder;
        font-size: 1rem;
        &.checked:after {
            content: 'X';
        }
    }
}