.root {
  .form {
    > div {
      padding: 10px 20px;
    }
    * label {
      padding-right: 5px;
    }
    * label,
    span {
      font-size: 1.9rem;
      font-weight: bold;
    }
    * input {
      height: 3rem;
      width: 100%;
      padding: 0;
      margin: 0 5px;
      border-radius: 10px;
      font-size: 2rem;
      font-weight: bold;
      font-family: "Acme";
      text-align: center;
    }
    * input[name="age"],
    * input[name="grade"],
    * input[name="group"] {
      width: 3rem;
    }
  }
}

.genderSelect {
  .input {
    // padding: 5rem;
    & > * {
    }
  }
}
