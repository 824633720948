.root {
    .form {
        &>* {
            margin-bottom: 50px;
        }
    }
}

.familySelect {
    .divideDottend {
        >*:not(:last-child) {
            border-right: dotted 2px;
        }
    }
    .bottomDottend {
        border-bottom: dotted 2px;
        >* {
            float: left;
        }
    }
}

.selectButton {
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.5);
    background-color: white;
    border-radius: 15px;
    padding: 10px;
}