.familySelect {
    .divideDottend {
        >*:not(:last-child) {
            border-right: dotted 2px;
        }
    }
    .bottomDottend {
        border-bottom: dotted 2px;
        >* {
            float: left;
        }
    }
}